import { useCallback, useMemo, useState, useEffect } from "react";
import MultiDropDownComponent from "../Analytics/multiDropDownComponent";
import { DateRangePicker } from "mui-daterange-picker";
import { DATE_RANGE_TYPE, getDateRanges } from "../../Util/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { COMPANY_ID_LIST } from "../../Reducers/companyIdListReducer";
import { constructCompanyIdList } from "../../Factory/factory";
import { getCompanyValues } from "../../AnalyticsHelperLib/AnalyticsDataHelper/analyticsDataHelperFunctions";

export const ReportsFilters = ({ onDownload, onSearchChange, onDateChange, onCompanyListChange }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [dateRangeLabel, setDateRangeLabel] = useState("This Week");
    const [initialDateRange, setInitialDateRange] = useState(null);
  
    const selectedCompanyIds = useSelector(state => state.companyIdList);

    useEffect(() => {
        const idList = selectedCompanyIds?.companyList ?? [];
        onCompanyListChange([...idList]);
    }, [selectedCompanyIds, onCompanyListChange]);

    useEffect(() => {
        dispatch({
            type: COMPANY_ID_LIST,
            payload: constructCompanyIdList(getCompanyValues([]))
        });
    }, [dispatch]);

    useEffect(() => {
        const ranges = getDateRanges();
        const thisWeek = ranges.find(range => range.label === "This Week");
        if (thisWeek) {
            setInitialDateRange({
                startDate: thisWeek.startDate,
                endDate: thisWeek.endDate
            });
            handleDateRangeChange({
                startDate: thisWeek.startDate,
                endDate: thisWeek.endDate
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      onSearchChange(e.target.value);
    };

    const definedRanges = useMemo(() => getDateRanges(), []);

    const handleDateRangeChange = useCallback((range) => {
      let startDate = range.startDate;
      let endDate = range.endDate;

      let timeFrame = DATE_RANGE_TYPE.RANGE;
      
      const matchedRange = definedRanges.find(definedRange => 
        definedRange.startDate.getTime() === startDate.getTime() &&
        definedRange.endDate.getTime() === endDate.getTime()
      );

      if (matchedRange) {
        timeFrame = matchedRange.type;
        setDateRangeLabel(matchedRange.label);
      } else {
        setDateRangeLabel(
          `${startDate.toLocaleDateString('en-US')} - ${endDate.toLocaleDateString('en-US')}`
        );
      }

      const rangeObj = {
        timeFrame,
        startDate,
        endDate
      }

      onDateChange(rangeObj);
    }, [definedRanges, onDateChange]);
  
    return (
      <div className='d-flex flex mb-4 align-items-center justify-content-between'>
        <div className="flex-grow-0">
          <MultiDropDownComponent/>
        </div>
        <div className="d-flex gap-3 align-items-stretch position-relative">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button 
            className="btn sd-btn-group border-deep gap-2 d-flex align-items-center"
            onClick={() => setOpen(!open)}
            style={{ whiteSpace: 'nowrap' }}
          >
            <div>{dateRangeLabel}</div> <div><i className="bi bi-chevron-down"></i></div>
          </button>
          <button 
            className="btn sd-btn-group border-deep"
            onClick={onDownload}
          >
            <i className="bi bi-download"></i>
          </button>
          {open && (
            <div className="position-absolute top-100 end-0 mt-2" style={{ minWidth: '700px' }}>
              <DateRangePicker
                open={open}
                toggle={() => setOpen(!open)}
                onChange={handleDateRangeChange}
                definedRanges={definedRanges}
                showPreview={false}
                style={{ padding: '1rem' }}
                maxDate={new Date()}
                initialDateRange={initialDateRange}
                minDate={(() => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - 3);
                  return date;
                })()}
              />
            </div>
          )}
        </div>
      </div>
    );
  };