export const EXTENDED_REPORT = "EXTENDED_REPORT";

const initialState = null; 

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EXTENDED_REPORT:
            return action.payload;
        default:
            return state;
    }
}

const storeMapping = {extendedReport: reducer};

export const extendedReportSelector = (state) => state.extendedReport;

export default storeMapping;
