export class ExtendedReport {
    constructor(data, startDate, endDate, timeFrame) {
        this.orders = data.success ? data.orders : [];
        this.startDate = startDate;
        this.endDate = endDate;
        this.timeFrame = timeFrame;
    }
    getRowCount() {
        return Object.values(this.orders).reduce((sum, arr) => sum + arr.length, 0);
    }

    getOrdersForCompany(companies) {
        let results = [];
        if (companies.length === 0) {
            results = Object.values(this.orders).flat();
        } else {
            results = Object.entries(this.orders).filter(([key, value]) => companies.includes(parseInt(key))).map(([key, value]) => value).flat();
        }
        results.sort((a, b) => a.id - b.id);
        return results;
    }
}
