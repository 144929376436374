import { useCallback, useEffect, useMemo, useState } from "react";
import "../../Stylesheets/tableV2.css";

const TableV2Header = ({ columns }) => {
  const visibleColumns = useMemo(() => columns.filter(col => col?.show ?? true), [columns]);
  
  return (
    <thead>
      <tr>
        {visibleColumns.map(column => (
          <th key={column.id} scope="col">{column.label}</th>
        ))}
      </tr>
    </thead>
  );
};

const TableV2Body = ({ data, columns, emptyText }) => {
  const visibleColumns = useMemo(() => columns.filter(col => col?.show ?? true), [columns]);

  if (data.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={visibleColumns.length} className="text-center">
            {emptyText}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {visibleColumns.map(column => (
            <td key={column.id}>
              {column.data(row)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const TableV2Pagination = ({ currentPage, totalPages, pageSize, totalEntries, onPageChange }) => {
  const [startEntry, endEntry] = useMemo(() => {
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(currentPage * pageSize, totalEntries);
    return [start, end];
  }, [currentPage, pageSize, totalEntries]);

  const getPageNumbers = useCallback(() => {
    const range = [];
    const maxPages = 5;
    
    if (totalPages <= maxPages) {
      // If total pages is 5 or less, show all pages
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Show 5 pages centered around current page
      let start = Math.max(1, currentPage - 2);
      let end = Math.min(totalPages, start + maxPages - 1);
      
      // Adjust start if end is maxed out
      if (end === totalPages) {
        start = Math.max(1, end - maxPages + 1);
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }
    }

    return range;
  }, [currentPage, totalPages]);

  return (
    <div className="pagination-container">
      <div className="entries-info">
        Showing {startEntry} to {endEntry} of {totalEntries} entries
      </div>
      <nav>
        <ul className="pagination justify-content-end">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button 
              className="page-link"
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            >
              First
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button 
              className="page-link" 
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          
          {getPageNumbers().map((pageNum, idx) => (
            <li 
              key={idx} 
              className={`page-item ${pageNum === currentPage ? 'active' : ''}`}
            >
              <button
                className="page-link"
                onClick={() => onPageChange(pageNum)}
              >
                {pageNum}
              </button>
            </li>
          ))}

          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button 
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button 
              className="page-link"
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const TableV2 = ({ data = [], config, emptyText = "No data available", searchTerm = "" }) => {
  const { columns, pagination, pageSize } = config;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const paginatedData = useMemo(() => 
    pagination 
      ? data.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      : data,
    [pagination, data, currentPage, pageSize]
  );

  const totalPages = useMemo(() => 
    Math.ceil(data.length / pageSize),
    [data.length, pageSize]
  );

  return (
    <div className="table-v2-wrapper">
      <div className="table-responsive">
        <table className="table table-hover">
          <TableV2Header columns={columns} />
          <TableV2Body data={paginatedData} columns={columns} emptyText={emptyText} />
        </table>

        {pagination && totalPages > 1 && (
          <TableV2Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            totalEntries={data.length}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
}

export default TableV2;