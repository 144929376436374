import { ExtendedReport } from "../Model/reportObjects";
import { EXTENDED_REPORT } from "../Reducers/extendedReportReducer";
import { fetchExtendedReport } from "../Util/queries";

export const getExtendedReport = (startDate, endDate, timeFrame) => async (dispatch) => {
    try {
        const response = await fetchExtendedReport(startDate, endDate, timeFrame);
        dispatch({
            type: EXTENDED_REPORT,
            payload: new ExtendedReport(response.data, startDate, endDate, timeFrame)
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: EXTENDED_REPORT,
            payload: new ExtendedReport({success: false}, startDate, endDate, timeFrame)
        });
    }
}