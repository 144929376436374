export const DATE_RANGE_TYPE = {
  RANGE: "RANGE",
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
};

export const getDateRanges = () => {
  const today = new Date();
  return [
    {
      label: "Today",
      type: DATE_RANGE_TYPE.TODAY,
      startDate: new Date(today.setHours(0, 0, 0, 0)),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    },
    {
      label: "Yesterday",
      type: DATE_RANGE_TYPE.YESTERDAY,
      startDate: new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0,0,0,0)),
      endDate: new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23,59,59,999)),
    },
    {
      label: "This Week",
      type: DATE_RANGE_TYPE.THIS_WEEK,
      startDate: (() => {
        const monday = new Date();
        monday.setDate(monday.getDate() - monday.getDay() + 1);
        monday.setHours(0, 0, 0, 0);
        return monday;
      })(),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    },
    {
      label: "Last Week",
      type: DATE_RANGE_TYPE.LAST_WEEK,
      startDate: (() => {
        const lastMonday = new Date();
        lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() - 6);
        lastMonday.setHours(0, 0, 0, 0);
        return lastMonday;
      })(),
      endDate: (() => {
        const lastSunday = new Date();
        lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
        lastSunday.setHours(23, 59, 59, 999);
        return lastSunday;
      })(),
    },
    {
      label: "This Month",
      type: DATE_RANGE_TYPE.THIS_MONTH,
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: new Date(today.setHours(23, 59, 59, 999)),
    },
    {
      label: "Last Month",
      type: DATE_RANGE_TYPE.LAST_MONTH,
      startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1),
      endDate: new Date(today.getFullYear(), today.getMonth(), 0),
    },
  ];
};


const MAX_EPOCH_SECONDS = 1e10;

export const parseDate = (rawDate) => {
  if (rawDate instanceof Date) {
    return rawDate;
  } else if (
    typeof rawDate === "number" ||
    (typeof rawDate === "string" && /^\d+$/.test(rawDate))
  ) {
    const timestamp =
      typeof rawDate === "number" ? rawDate : parseInt(rawDate, 10);
    if (timestamp < MAX_EPOCH_SECONDS) {
      return new Date(timestamp * 1000);
    } else {
      return new Date(timestamp);
    }
  } else if (typeof rawDate === "string") {
    if (/^\d{1,2}:\d{1,2}:\d{1,2}$/.test(rawDate)) {
      rawDate = "1970-01-01T" + rawDate + "Z";
    } 
    return new Date(rawDate);
  } else {
    console.error(rawDate);
    throw new Error("Unsupported date format");
  }
};

export const DATE_FORMATS = {
    DD_MMMc_YYYY: "DD_MMMc_YYYY",
    MMM_DDc_YYYY: "MMM_DDc_YYYY",
    HHcMM_AP: "HHcMM_AP",
}

export const formatDate = (date, format) => {
    date = parseDate(date);
    switch (format) {
        case DATE_FORMATS.DD_MMMc_YYYY: {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            return `${day} ${month}, ${year}`;
        }
        case DATE_FORMATS.MMM_DDc_YYYY: {
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month} ${day}, ${year}`;
        }
        case DATE_FORMATS.HHcMM_AP: {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12;
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        }
        default:
            return date.toLocaleString();
    }
}