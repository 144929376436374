import {getJSON, paymentDelJSON, paymentGetJSON, paymentPostJSON, paymentPutJSON, postJSON, putJSON, reportGetJSON} from "./requests";
import {getAchInitReqUrl} from "./constructAPIReq";

export const findEnterpriseLoginInfo = async (data) => await postJSON('/auth/login', data);
export const findAccessToken = async (refreshToken) => await postJSON('/auth/refresh/' + refreshToken, {});
export const findEnterpriseBilling = async () => await getJSON('/admin/billing');
export const findEnterpriseDetails = async () => await getJSON('/admin/info');
export const findEnterpriseAccounts = async () => await getJSON('/admin/companies');
export const findAnalyticsDetails = async (selectedDate, selectedType) => await getJSON('/admin/analytics/' + selectedType + '/' + selectedDate);
export const updatePartnerPassword = async (data) => await paymentPutJSON('/payment/partners/details/update', data);
// this is the invoice API
export const findPartnerPayments = async () => await paymentGetJSON('/enterprise/invoices');
// Card info API
export const findPartnerPaymentsCard = async () => await paymentGetJSON('/enterprise/payment-info');
// Add Card API
export const addPartnerPaymentCard = async (data) => await paymentPutJSON('/enterprise/card', data);
export const createPartnerPaymentUser = async () => await paymentPostJSON('/payment/partners/users');
// update Card API
export const updatePartnerPaymentCard = async (data) => await paymentPutJSON('/enterprise/card', data);
// Remove Card API
export const removePartnerPaymentCard = async () => await paymentDelJSON('/enterprise/card');
export const updatePartnerBankInfo = async (data) => await paymentPutJSON('/payment/partners/payout-info', data);
export const addNewPartner = async (data) => await paymentPostJSON('/payment/partners/add', data);
export const findReferralLink = async () => await paymentGetJSON('/payment/partners/invitation-link');
// confusion
export const fetchACHBankInfo = async () => await paymentGetJSON('/enterprise/payment-info');
export const findThirdPartyWeeklyBillingDetails = async () => await paymentGetJSON('/payment/third-party/weekly-billing');
export const findMagicLink = async (data) => await postJSON('/auth/access/account', data, true);
export const initACH = async () =>  await paymentPostJSON(getAchInitReqUrl(), {});
export const setBillingInformation = async (data) => await putJSON('/invoice/recipient/info', data);
// notifications
export const findNotificationEmails = async () => await getJSON('/notification/emails');
export const updateNotificationEmails = async (data) => await putJSON('/notification/emails', data);
// report
export const fetchExtendedReport = async (startDate, endDate, timeFrame) => await reportGetJSON('/enterprise/extended/orders', {
    timeframe: timeFrame,
    startDate: startDate,
    endDate: endDate
});