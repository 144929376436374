import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../Actions/loginAction";
import {BILLING_COMPONENT} from "../Billing/billingPageWrapper";
import {DASHBOARD_COMPONENT} from "../Dashboard/dashboardWrapper";
import {constructSelectedComponent} from "../../Factory/factory"
import {PROFILE_COMPONENT} from "../Profile/profilePageWrapper";
import {ANALYTICS_COMPONENT} from "../Analytics/analyticsWrapper";
import {PAYMENT_COMPONENT} from "../Payments/paymentsWrapper";
import {getOrgNameBasedOnLevel} from "../../Util/dataHelper";
import { NOTIFICATIONS_COMPONENT } from "../Notifications/notificationsWrapper";
import { ADMIN } from "../../Enums/accessLevels";
import { ENTERPRISE } from "../../Enums/administratorLevels";
import { REPORTS_COMPONENT } from "../Reports/reportsWrapper";
// import {PAYMENT_SETUP} from "../PaymentSetup/paymentSetupWrapper";

const LEFT_SIDE_BAR = "LEFT_SIDE_BAR";
const LEFT_SIDE_BAR_SELECTED = "LEFT_SIDE_BAR_SELECTED";
const PARTNER_TYPE_AFFILIATE = "AFFILIATE";
const PARTNER_TYPE_RESELLER = "RESELLER";

const LeftNavbar = () => {
    const sideBarActive = useSelector(state => state.leftSideBarActive);
    const partnerDetails = useSelector(state => state.partnerDetails);
    const globalSelectedComponent = useSelector(state => state.selectedComponent);
    const [selectedComponent, setSelectedComponent] = useState(ANALYTICS_COMPONENT);
    const dispatch = useDispatch();
    
    const setActiveComponent = (component) => {
        dispatch({
            type: LEFT_SIDE_BAR_SELECTED,
            payload: constructSelectedComponent(component)
        })
    }

    const showEarning = () => {
        if (partnerDetails.partnerType === PARTNER_TYPE_AFFILIATE) return true;
        else return false;
    }

    const showNotifications = () => {
        return (partnerDetails.getAccessLevel() === ADMIN && partnerDetails.getAdminLevel() === ENTERPRISE);
    }

    const showReports = () => {
        return partnerDetails.getAdminLevel() === ENTERPRISE;
    }

    useEffect(() => {
        if (globalSelectedComponent !== null && globalSelectedComponent?.component !== selectedComponent) {
            setSelectedComponent(globalSelectedComponent.component);
        }
    }, [globalSelectedComponent?.component])

    if (partnerDetails === null) return <></>

    const navBarConfig = [
        {
            component: ANALYTICS_COMPONENT,
            icon: "bi-graph-up",
            label: "Analytics"
        },
        {
            component: DASHBOARD_COMPONENT,
            icon: "bi-view-stacked",
            label: "Accounts"
        },
        {
            component: BILLING_COMPONENT,
            icon: "bi-receipt",
            label: "Billing & Usage",
            show: !showEarning()
        },
        {
            component: REPORTS_COMPONENT,
            icon: "bi-file-text",
            label: "Reports",
            show: showReports()
        },
        {
            component: PAYMENT_COMPONENT,
            icon: "bi-credit-card",
            label: "Payment"
        },
        {
            component: NOTIFICATIONS_COMPONENT,
            icon: "bi-megaphone",
            label: "Notifications",
            show: showNotifications()
        },
        {
            component: PROFILE_COMPONENT,
            icon: "bi-person",
            label: "Profile"
        },
        {
            component: "logout",
            icon: "bi-box-arrow-right",
            label: "Logout",
            onClick: () => dispatch(logout())
        }
    ];

    return (
        <div
            className={`side-navbar d-flex justify-content-between flex-wrap flex-column ${sideBarActive ? "activeNav" : ""}`}>
            <ul className="nav list-group flex-column w-100">
                {navBarConfig.map((item, index) => (
                    (item.show === undefined || item.show) && (
                        <li 
                            key={index}
                            className={`nav-link ${selectedComponent === item.component ? "nav-selected": ""}`}
                            onClick={() => item.onClick ? item.onClick() : setActiveComponent(item.component)}
                        >
                            <i className={`bi ${item.icon}`}></i>
                            <span className="mx-2">{item.label}</span>
                        </li>
                    )
                ))}
            </ul>
        </div>
    )
}

export default LeftNavbar;
export {LEFT_SIDE_BAR, LEFT_SIDE_BAR_SELECTED, PARTNER_TYPE_AFFILIATE, PARTNER_TYPE_RESELLER};
