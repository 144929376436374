import ComponentHeader from "../CommonComponents/componentHeader";
import React, { useEffect, useState, useMemo } from "react";
import LoadingSpinner from "../CommonComponents/loadingSpinner";
import { ReportsFilters } from "./reportsFilters";
import { useDispatch, useSelector } from "react-redux";
import { getExtendedReport } from "../../Actions/extendedReportAction";
import { extendedReportSelector } from "../../Reducers/extendedReportReducer";
import ExtendedReportTable from "./extendedReportTable";

export const REPORTS_COMPONENT = "REPORTS_COMPONENT";

const ReportsWrapper = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [handleDownload, setHandleDownload] = useState(() => () => {});

  const extendedReport = useSelector(extendedReportSelector);

  const dispatch = useDispatch();

  const invalidStore = useMemo(
    () => extendedReport === null || extendedReport.startDate !== dateRange?.startDate?.toISOString() || 
      extendedReport.endDate !== dateRange?.endDate?.toISOString() || extendedReport.timeFrame !== dateRange?.timeFrame, 
    [extendedReport, dateRange]);

  useEffect(() => {
    if (Object.keys(dateRange).length > 0 && invalidStore) {
      setShowLoading(true);
      dispatch(getExtendedReport(dateRange.startDate.toISOString(), dateRange.endDate.toISOString(), dateRange.timeFrame));
    }
  }, [dateRange, dispatch, invalidStore]);

  useEffect(() => {
    if (Object.keys(dateRange).length > 0 && !invalidStore) {
      setShowLoading(false);
    }
  }, [invalidStore, dateRange]);

  return (
    <>
      <ComponentHeader title={"Extended Report"} />
      <div className="mt-4 d-flex flex-column">
        <div className={`analytics-parent d-flex flex-column ${showLoading ? 'disabled-div' : ''}`}>
          <ReportsFilters 
            onDownload={handleDownload}
            onSearchChange={setSearchTerm}
            onDateChange={setDateRange}
            onCompanyListChange={setSelectedCompanies}
          />
          <ExtendedReportTable 
            data={extendedReport} 
            selectedCompanies={selectedCompanies} 
            searchTerm={searchTerm}
            setDownloadHandler={setHandleDownload}
          />
        </div>
        {showLoading && <div>
          <LoadingSpinner/>
        </div>}
      </div>
    </>
  );
};

export default ReportsWrapper;
