import { useEffect, useMemo, useState } from "react";
import TableV2 from "../TableV2/tableV2";
import { DATE_FORMATS, formatDate } from "../../Util/dateUtils";

const columnConfig = [
    {
        id: "place_date",
        label: "Date",
        data: x => formatDate(x.place_time, DATE_FORMATS.MMM_DDc_YYYY),
    },
    {
        id: "order_no",
        label: "Order No.",
        data: x => x.order_no,
    },
    {
        id: "pickup_from",
        label: "Pick-up From",
        data: x => x.pick_up_from,
    },
    {
        id: "pickup_address",
        label: "Pick-up Address",
        data: x => x.pick_up_address,
        show: false,
    },
    {
        id: "customer_name",
        label: "Deliver To",
        data: x => x.customer_name,
    },
    {
        id: "customer_address",
        label: "Delivery Address",
        data: x => x.customer_address,
        show: false,
    },
    {
        id: "driver",
        label: "Driver",
        data: x => x.driver || "--",
    },
    {
        id: "total",
        label: "Total",
        data: x => "€" + Number(x.total).toFixed(2),
    },
    {
        id: "payment_method",
        label: "Payment Method",
        data: x => x.payment_method || "N/A",
    },
    {
        id: "delivery_fee",
        label: "Delivery Fee",
        data: x => "€" + Number(x.delivery_fee).toFixed(2),
    },
    {
        id: "driver_payment",
        label: "Base Driver Pay",
        data: x => "€" + Number(x.driver_payment).toFixed(2),
    },
    {
        id: "cash_tip",
        label: "Online Tips",
        data: x => "€" + Number(x.cash_tip).toFixed(2),
    },
    {
        id: "delivery_distance",
        label: "Distance (mile)",
        data: x => (Math.round(x.delivery_distance * 100) / 100).toFixed(2),
    },
    {
        id: "feedback",
        label: "Feedback",
        data: x => {
            if (x.feedback == null || x.feedback === 0) return 'N/A';
            if (x.feedback > 0 && x.feedback <= 5)
                return <div className="feedback-with-star">
                            <span className="feedback-number">{x.feedback}</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                            </span>
                       </div>
        }, 
        textData: x => {
            if (x.feedback == null || x.feedback === 0) return 'N/A';
            else return x.feedback;
        }
    },
    {
        id: "place_time",
        label: "Placement Time",
        data: x => formatDate(x.place_time, DATE_FORMATS.HHcMM_AP),
        show: false,
    },
    {
        id: "expected_pickup_time",
        label: "Requested Pickup Time",
        data: x => formatDate(x.expected_pickup_time, DATE_FORMATS.HHcMM_AP),
        show: false,
    },
    {
        id: "expected_delivery_date",
        label: "Requested Delivery Date",
        data: x => formatDate(x.expected_delivery_date, DATE_FORMATS.MMM_DDc_YYYY),
        show: false,
    },
    {
        id: "expected_delivery_time",
        label: "Requested Delivery Time",
        data: x => formatDate(x.expected_delivery_time, DATE_FORMATS.HHcMM_AP),
        show: false,
    },
    {
        id: "assign_time",
        label: "Order Assignment Time",
        data: x => x.assign_time ? formatDate(x.assign_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "accept_time",
        label: "Order Accepted Time",
        data: x => x.accept_time ? formatDate(x.accept_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "ready_to_pickup_time",
        label: "Ready to Pickup Time",
        data: x => x.ready_to_pickup_time ? formatDate(x.ready_to_pickup_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "pickup_time",
        label: "Pick up Time",
        data: x => x.pickup_time ? formatDate(x.pickup_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "out_the_door",
        label: "Out the Door",
        data: x => x.out_the_door ? formatDate(x.out_the_door, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "on_the_way_time",
        label: "On The Way Time",
        data: x => x.on_the_way_time ? formatDate(x.on_the_way_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "at_the_customer",
        label: "At the Customer",
        data: x => x.at_the_customer ? formatDate(x.at_the_customer, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "completion_date",
        label: "Completion Date",
        data: x => x.completion_time ? formatDate(x.completion_time, DATE_FORMATS.MMM_DDc_YYYY) : "",
        show: false,
    },
    {
        id: "completion_time",
        label: "Completion Time",
        data: x => x.completion_time ? formatDate(x.completion_time, DATE_FORMATS.HHcMM_AP) : "",
        show: false,
    },
    {
        id: "assigner_name",
        label: "Assigned by",
        data: x => x.assigner_name || "N/A",
        show: false,
    },
    {
        id: "delivery_instruction",
        label: "Delivery Instruction",
        data: x => x.delivery_instruction || "N/A",
        show: false,
    },
    {
        id: "delivery_note",
        label: "Delivery Note",
        data: x => x.delivery_note || "N/A",
        show: false,
    },
    {
        id: "signature_path",
        label: "Proof of Delivery",
        data: x => {
            if (!x.signature_path) return "N/A";
            const signaturePath = x.signature_path.split(":_SIG_:")[0].split(':_:_:')[0];
            return signaturePath;
        },
    },
    {
        id: "placement_to_out_the_door",
        label: "Placement to Out the Door (in mins)",
        data: x => x.placement_to_out_the_door,
        show: false,
    },   
    {
        id: "out_the_door_to_at_the_customer",
        label: "Out the Door to At the Customer (in mins)",
        data: x => x.out_the_door_to_at_the_customer,
        show: false,
    },
    {
        id: "placement_to_at_the_customer",
        label: "Placement to At the Customer (in mins)",
        data: x => x.placement_to_at_the_customer,
        show: false,
    }
]

const tableConfig = {
    columns: columnConfig,
    pagination: true,
    pageSize: 50
}

const ExtendedReportTable = ({ data, selectedCompanies, searchTerm, setDownloadHandler }) => {
  const orders = useMemo(() => {
    if (data === null) return [];
    return data.getOrdersForCompany(selectedCompanies);
  }, [data, selectedCompanies]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const filteredOrders = useMemo(() => {
    if (!debouncedSearchTerm) return orders;
    
    return orders.filter(row => {
      return tableConfig.columns.some(column => {
        if (!(column.show ?? true)) return false;
        const cellValue = column.data(row);
        const stringValue = typeof cellValue === 'number' ? cellValue.toString() : cellValue;
        return typeof stringValue === 'string' && 
               stringValue.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
      });
    });
  }, [orders, debouncedSearchTerm]);

  useEffect(() => {
    setDownloadHandler(() => () => {
      const csvRows = [];

      const headers = columnConfig.map(col => col.label);
      csvRows.push(headers.join(','));

      filteredOrders.forEach(order => {
        const row = columnConfig.map(col => {
          let value = col.textData ? col.textData(order) : col.data(order);
          if (typeof value === 'string') {
            value = value.replace(/"/g, '""');
            value = `"${value}"`;
          }
          return value;
        });
        csvRows.push(row.join(','));
      });

      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Extended Order Report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }, [setDownloadHandler, filteredOrders]);

  return <div style={{width: "100%"}}>
    <TableV2 data={filteredOrders} config={tableConfig} searchTerm={searchTerm} />
  </div>;
};

export default ExtendedReportTable;
